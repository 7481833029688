import React from 'react';
import SEO from './seo';
import NavBar from './navbar';
import {MDXProvider} from '@mdx-js/react';
import Footer from './footer';

const MdxLayout = ({pageTitle, children}) => (
  <div>
    <SEO />
    <NavBar />
    <main className="container mx-auto prose prose-red">
      <title>{pageTitle}</title>
      <article>
        <h1>{pageTitle}</h1>
        <MDXProvider>{children}</MDXProvider>
      </article>
    </main>
    <hr className="mt-6" />
    <Footer />
  </div>
);

export default MdxLayout;
