import * as React from 'react';
import {Link} from 'gatsby';
import {StaticImage} from 'gatsby-plugin-image';

const NavBar = () => {
  return (
    <div>
      <div className="p-4">
        <Link to="/">
            <StaticImage
              src="../images/littlelikeme-logo.png"
              alt="littlelike.me ™"
              height={40}
              placeholder="tracedSVG"
            />
        </Link>
      </div>
      <hr />
    </div>
  );
};
export default NavBar;
